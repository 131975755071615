var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('ViewTemplateWithTable',{attrs:{"panel":_vm.panel,"templateList":_vm.templateList,"propsParam":_vm.propsParam}},[_c('div',{attrs:{"slot":"content-buttons-table-header"},slot:"content-buttons-table-header"}),_c('div',{attrs:{"slot":"content-filter-horizontal"},slot:"content-filter-horizontal"},[_c('b-row',[_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"4","xl":"4"}},[_c('RadioGroup',{attrs:{"title":"Agrupado por","initialFieldTarget":{ text: 'Data de Faturamento', value: 'Data de Faturamento' },"fieldTarget":"Any","options":[
    { text: 'Data de Faturamento', value: 'Data de Faturamento' },
    { text: 'Locação', value: 'Locação' } ]},model:{value:(_vm.propsParam.any),callback:function ($$v) {_vm.$set(_vm.propsParam, "any", $$v)},expression:"propsParam.any"}})],1),_c('b-col',{attrs:{"xs":"12","sm":"12","md":"12","lg":"8","xl":"8"}},[_c('RadioGroup',{attrs:{"title":"Status","initialFieldTarget":{ text: _vm.propsParam.status, value: _vm.propsParam.status },"fieldTarget":"Status","options":[
    { text: 'Hoje', value: 'Hoje' },
    { text: 'Semana Atual', value: 'Semana Atual' },
    { text: 'Mês Atual', value: 'Mês Atual' },
    { text: 'Ano Atual', value: 'Ano Atual' },
    { text: 'Pendente', value: 'Pendente' },
    { text: 'Agendada', value: 'Agendada' } ]},model:{value:(_vm.propsParam.status),callback:function ($$v) {_vm.$set(_vm.propsParam, "status", $$v)},expression:"propsParam.status"}})],1)],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }